import { gql, useMutation } from '@apollo/client';
import { DematAccountType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanel, FormPanelWithReadMode } from 'components/FormPanel';

const ADD_DEMAT_ACCOUNT_MUTATION = gql(`
  mutation AddDematAccount(
    $investmentEntityId: ID!
    $depositoryType: DematDepositoryTypeEnumType!
    $dpId: String!
    $clientId: String!
    $dematAccountNumber: String!
    $cmlDocument: Upload!
  ) {
    upsertDematAccount(
      investmentEntityId: $investmentEntityId
      depositoryType: $depositoryType
      dpId: $dpId
      clientId: $clientId
      dematAccountNumber: $dematAccountNumber
      cmlDocument: $cmlDocument
    ) {
      id
      depositoryType
      dpId
      clientId
      dematAccountNumber
      cmlDocument {
        url
        name
      }
    }
  }
`);

const UPDATE_PAN_MUTATION = gql(`
  mutation UpdatePan(
    $id: ID!
    $pan: String!
  ) {
    updateKyc(
      id: $id
      pan: $pan
    ) {
      id
      pan
    }
  }
`);

const AddDematAccountDetails: FC<{
  kycId: string;
  investmentEntityId: string;
  dematAccountData: DematAccountType;
  investmentEntityName: string;
  pan: string;
}> = ({ kycId, investmentEntityId, dematAccountData, investmentEntityName, pan }) => {
  const [addDematAccount, { loading, error }] = useMutation(ADD_DEMAT_ACCOUNT_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: `InvestmentEntityType:${investmentEntityId}`,
        fields: {
          dematAccount() {
            const newDematAccountRef = cache.writeFragment({
              data: data.upsertDematAccount,
              fragment: gql(`
                fragment NewDematAccount on DematAccountType {
                  id
                  depositoryType
                  dpId
                  clientId
                  dematAccountNumber
                  cmlDocument {
                    url
                    name
                  }
                }
              `),
            });
            return newDematAccountRef;
          },
        },
      });
    },
  });

  const [updatePan, { loading: updatePanLoading, error: updatePanError }] = useMutation(
    UPDATE_PAN_MUTATION,
    {
      // update(cache, { data }) {
      //   if (!data) return;
      //   cache.modify({
      //     id: `InvestmentEntityType:${investmentEntityId}`,
      //     fields: {
      //       kyc() {
      //         return data.updateKyc;
      //       },
      //     },
      //   });
      // },
    }
  );

  const getDematFormFields = (dematAccount: DematAccountType) => {
    return (
      <>
        <FormInput
          type="text"
          fieldName="nameOfAccountHolder"
          label="Name of Account Holder"
          defaultValue={investmentEntityName}
          validators={{ required: true }}
          editable={false}
        />
        <FormInput
          type="text"
          fieldName="pan"
          label="PAN"
          defaultValue={pan}
          validators={{ required: true }}
          editable={!pan}
        />
        <FormInput
          type="radio-group"
          fieldName="depositoryType"
          label="Depository Type"
          defaultValue={dematAccount?.depositoryType}
          options={[
            { label: 'NSDL', value: 'NSDL' },
            { label: 'CDSL', value: 'CDSL' },
          ]}
          validators={{ required: true }}
          editable={!dematAccount?.depositoryType}
        />
        <FormInput
          type="text"
          fieldName="dpId"
          label="DP ID"
          defaultValue={dematAccount?.dpId}
          validators={{ required: true }}
          editable={!dematAccount?.dpId}
        />
        <FormInput
          type="text"
          fieldName="clientId"
          label="Client ID"
          defaultValue={dematAccount?.clientId}
          validators={{ required: true }}
          editable={!dematAccount?.clientId}
        />
        <FormInput
          type="text"
          fieldName="dematAccountNumber"
          label="Demat Account Number"
          defaultValue={dematAccount?.dematAccountNumber}
          validators={{ required: true }}
          editable={!dematAccount?.dematAccountNumber}
        />
        <FormInput
          type="upload"
          fieldName="cmlDocument"
          label="CML File"
          defaultValue={dematAccount?.cmlDocument}
          validators={{
            required: true,
          }}
          maxFileSize={10}
          editable={!dematAccount?.cmlDocument}
        />
      </>
    );
  };

  return (
    <>
      {dematAccountData && dematAccountData.cmlDocument ? (
        <FormPanelWithReadMode
          loading={loading}
          error={error}
          disableEdit={true}
          title="Demat Account Details"
          onSubmit={() => {}}
        >
          {getDematFormFields(dematAccountData)}
        </FormPanelWithReadMode>
      ) : (
        <FormPanel
          loading={loading || updatePanLoading}
          error={error || updatePanError}
          onSubmit={async data => {
            if (data.pan) {
              await updatePan({
                variables: {
                  id: kycId,
                  pan: data.pan,
                },
              });
            }
            return addDematAccount({
              variables: {
                investmentEntityId: investmentEntityId,
                nameOfAccountHolder: data.nameOfAccountHolder,
                depositoryType: data.depositoryType,
                dpId: data.dpId,
                clientId: data.clientId,
                dematAccountNumber: data.dematAccountNumber,
                cmlDocument: data.cmlDocument,
              },
            });
          }}
        >
          {getDematFormFields(dematAccountData)}
        </FormPanel>
      )}
    </>
  );
};

export default AddDematAccountDetails;
