import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { SyndicateTypeEnumType, UserStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormInput, FormPanel } from 'components/FormPanel';

import { getUser } from 'utils/auth';

const CREATE_RUV_MUTATION = gql(`
  mutation CreateRUV(
    $name: String!
    $tagline: String!
    $isPrivate: Boolean!
    $applicationInformation: ApplicationInformationInputType!
    $type: SyndicateTypeEnumType!
    $isOnboarding: Boolean
  ) {
    createSyndicate(
      name: $name
      tagline: $tagline
      isPrivate: $isPrivate
      applicationInformation: $applicationInformation
      type: $type
      isOnboarding: $isOnboarding
    ) {
      id
      name
      isPrivate
      status
      createdAt
      updatedAt
    }
  }
`);

const RUVCompanyProfile: FC = () => {
  const navigate = useNavigate();
  const currentUser = getUser();
  const [createSyndicate, { loading, error }] = useMutation(CREATE_RUV_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          id: currentUser.id, // The ID of the dashboard is the same as the user ID
          __typename: 'DashboardType',
        }),
        fields: {
          syndicates(existingSyndicatesRef) {
            const newSyndicateNodeRef = cache.writeFragment({
              data: data.createSyndicate,
              fragment: gql(`
                fragment NewSyndicate on SyndicateType {
                  id
                  name
                  isPrivate
                  status
                  createdAt
                  updatedAt
                }
              `),
            });
            const updatedSyndicatesRef = { ...existingSyndicatesRef };
            updatedSyndicatesRef.nodes = [newSyndicateNodeRef, ...updatedSyndicatesRef.nodes];
            return updatedSyndicatesRef;
          },
        },
      });
      cache.modify({
        id: cache.identify({
          id: currentUser.id,
          __typename: 'UserType',
        }),
        fields: {
          status() {
            return UserStatusEnumType.RuvReview;
          },
        },
      });
    },
  });

  const renderContent = () => {
    return (
      <FormPanel
        loading={loading}
        error={error}
        onSubmit={data => {
          createSyndicate({
            variables: {
              name: data.name,
              tagline: data.tagline,
              isPrivate: data.isPrivate === 'true',
              type: SyndicateTypeEnumType.Ruv,
              isOnboarding: true,
              applicationInformation: {
                expectedDealsPerYear: data.expectedDealsPerYear,
                previousFundraisingExperience: data.previousFundraisingExperience,
                existingInvestorNetwork: data.existingInvestorNetwork,
              },
            },
          }).then(data => {
            navigate(`/syndicate-dashboard/${data.data?.createSyndicate?.id}/deals/create`);
          });
        }}
      >
        <FormInput
          fieldName="name"
          type="text"
          defaultValue=""
          label={'Startup Name *'}
          placeholder={'Example: AngelList India'}
          validators={{ required: true, minLength: 2 }}
        />
        <FormInput
          fieldName="tagline"
          type="text"
          defaultValue=""
          placeholder='Example: "Market access and infrastructure for investing in top-tier, venture-backed companies in India."'
          label={'Description *'}
          helperText={'One-liner describing your startup'}
          fullWidth
          validators={{
            required: true,
            maxLength: 255,
            minLength: 10,
          }}
        />
        <FormInput
          fullWidth
          type="radio-group"
          fieldName="isPrivate"
          label="Syndicate Profile Privacy *"
          helperText="Do you want your syndicate profile to be public or private on AngelList India?"
          defaultValue={'true'}
          options={[
            {
              label: 'Public: Investors can discover and apply to back your syndicate.',
              value: 'false',
            },
            {
              label: 'Private: Only people with a direct link can view your profile.',
              value: 'true',
            },
          ]}
          validators={{
            required: true,
          }}
          hidden={true}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="expectedDealsPerYear"
          label="Expected Dealflow *"
          helperText="How many deals do you plan to run per year?"
          defaultValue={'I only want to run 1 deal now'}
          options={[
            { label: 'More than 20 deals per year', value: 'More than 20 deals per year' },
            { label: 'Between 5 and 20 deals per year', value: 'Between 5 and 20 deals per year' },
            { label: 'Fewer than 5 deals per year', value: 'Fewer than 5 deals per year' },
            { label: 'I only want to run 1 deal now', value: 'I only want to run 1 deal now' },
          ]}
          validators={{
            required: true,
          }}
          hidden={true}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="previousFundraisingExperience"
          label="Fundraising Experience *"
          helperText="How much have you raised from LPs previously?"
          defaultValue={'I haven’t raised money from investors'}
          options={[
            { label: 'I raised more than ₹10 Crore', value: 'I raised more than ₹10 Crore' },
            {
              label: 'I raised between ₹1 Crore and ₹10 Crore',
              value: 'I raised between ₹1 Crore and ₹10 Crore',
            },
            { label: 'I raised less than ₹1 Crore', value: 'I raised less than ₹1 Crore' },
            {
              label: 'I haven’t raised money from investors',
              value: 'I haven’t raised money from investors',
            },
          ]}
          validators={{
            required: true,
          }}
          hidden={true}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="existingInvestorNetwork"
          label="Existing LP network *"
          helperText="How many LPs are part of your current network for deals?"
          defaultValue={'I have enough LPs to fill my deals'}
          options={[
            {
              label: 'I have enough LPs to fill my deals',
              value: 'I have enough LPs to fill my deals',
            },
            {
              label: 'I have some LPs, not enough to fill my deals',
              value: 'I have some LPs, not enough to fill my deals',
            },
            { label: 'I don’t have any LPs', value: 'I don’t have any LPs' },
          ]}
          validators={{
            required: true,
          }}
          hidden={true}
        />
      </FormPanel>
    );
  };

  return renderContent();
};

export default RUVCompanyProfile;
