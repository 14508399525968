import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  InvestmentEntityStatusEnumType,
  InvestmentEntityTypeEnumType,
} from '__generated__/graphql';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import LoadingIndicator from 'primitives/LoadingIndicator';

import { getUser } from 'utils/auth';

import { countryPhoneCodes } from '../utils/country-codes';

const GET_USER_PROFILE_QUERY = gql(`
  query UserProfile($id: ID!) {
    user(id: $id) {
      id
      bio
      name
      image {
        url
        name
      }
      linkedInProfileUrl
      phoneNumber
      countryCode
      investmentEntities {
        id
        status
        createdAt
      }
    }
  }
`);

const UPDATE_USER_PROFILE_QUERY = gql(`
  mutation UpdateUserProfile($id: ID!, $bio: String, $image: Upload, $phoneNumber: String, $linkedInProfileUrl: String) {
    updateUserProfile(id: $id, bio: $bio, image: $image, phoneNumber: $phoneNumber, linkedInProfileUrl: $linkedInProfileUrl) {
      id
      bio
      image {
        url
        name
      }
      phoneNumber
      linkedInProfileUrl
    }
  }
`);

const UPSERT_INVESTMENT_ENTITY_MUTATION = gql(`
  mutation UpsertInvestmentEntity($id: ID, $type: InvestmentEntityTypeEnumType!, $name: String!) {
    upsertInvestmentEntity(id: $id, type: $type, name: $name) {
      id
      type
      name
    }
  }
`);

const UpdateUserProfileRUV: FC = () => {
  const navigate = useNavigate();
  const { investmentEntityId: urlInvestmentEntityId } = useParams();
  const user = getUser();

  const { data, loading, error, refetch } = useQuery(GET_USER_PROFILE_QUERY, {
    variables: { id: user.id },
  });

  const [updateUserProfile, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_USER_PROFILE_QUERY);

  const [upsertInvestmentEntity, { loading: upsertLoading, error: upsertError }] = useMutation(
    UPSERT_INVESTMENT_ENTITY_MUTATION
  );

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const investmentEntities = data.user.investmentEntities;

  const isUserWithActiveEntity = investmentEntities.some(
    entity => entity.status === InvestmentEntityStatusEnumType.Active
  );

  // If the user has no active entity, continue with the onboarding flow
  // If the user has a draft entity, prevent them from creating a new entity
  const latestDraftInvestmentEntity = [...investmentEntities]
    .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
    .find(entity => entity.status === InvestmentEntityStatusEnumType.Draft);

  return (
    <FormPanel
      loading={updateLoading && upsertLoading}
      error={updateError && upsertError}
      onSubmit={formData => {
        updateUserProfile({
          variables: {
            id: user.id,
            bio: formData.bio,
            image: data?.user.image !== formData.image ? formData.image : undefined,
            phoneNumber: formData.countryCode.toString() + '-' + formData.phoneNumber.toString(),
            linkedInProfileUrl: formData.linkedInProfileUrl,
          },
        })
          .then(() => {
            // NOTE: Save user profile + create IE at this step for RUV onboarding flow
            return upsertInvestmentEntity({
              variables: {
                id: urlInvestmentEntityId || latestDraftInvestmentEntity?.id || '',
                type: InvestmentEntityTypeEnumType.Individual,
                name: user.name,
              },
            });
          })
          .then(resp => {
            const investmentEntityId = resp?.data?.upsertInvestmentEntity.id;
            if (isUserWithActiveEntity) {
              navigate('/');
              return;
            }
            if (investmentEntityId) {
              navigate(
                `/onboarding/complete-profile-ruv/investment-entity/${investmentEntityId}/kyc`
              );
            } else {
              navigate('/onboarding/complete-profile-ruv');
            }
          });
      }}
      submitButtonLabel="Save and continue"
    >
      <FormInput
        fullWidth
        type="textarea"
        fieldName="bio"
        label="Bio *"
        helperText="Your bio will help us introduce you to investors, fund managers and other founders"
        defaultValue={data?.user.bio}
        placeholder="Hi! I am Rahul Sharma, and I'm deeply interested in tech and finance."
        validators={{
          required: true,
          minLength: 8,
        }}
      />
      <FormInput
        fullWidth
        type="upload"
        fieldName="image"
        label="Avatar"
        helperText="Your profile picture"
        defaultValue={data?.user.image}
        maxFileSize={10}
      />
      <div className="flex flex-row w-full col-span-6 space-x-3">
        <FormInput
          fullWidth
          type="dropdown"
          options={countryPhoneCodes}
          fieldName="countryCode"
          label="Country Code *"
          defaultValue={data?.user.countryCode ?? '+91'}
          placeholder="Country code"
          validators={{
            required: true,
            isCountryCode: true,
          }}
        />
        <div className="flex-1 w-full">
          <FormInput
            fullWidth
            type="number"
            fieldName="phoneNumber"
            label="Phone Number *"
            placeholder="9999900000"
            defaultValue={data?.user.phoneNumber}
            validators={{
              required: true,
              isPhoneNumber: true,
            }}
          />
        </div>
      </div>
      <FormInput
        fullWidth
        type="text"
        fieldName="linkedInProfileUrl"
        label="LinkedIn Profile URL"
        placeholder="https://www.linkedin.com/in/rahulsharma"
        defaultValue={data?.user.linkedInProfileUrl}
        validators={{
          isLinkedinProfile: true,
        }}
      />
    </FormPanel>
  );
};

export default UpdateUserProfileRUV;
