import { useQuery } from '@apollo/client';
import { gql } from '__generated__';
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';
import ProtectedRoute from 'components/ProtectedRoute';

import LoadingIndicator from 'primitives/LoadingIndicator';

import { getUser, isAdminLoggingInAsUser, logout } from 'utils/auth';

import authenticationRoutes from './Authentication/routes';
import CreateFund from './CreateFund';
import CreateSyndicate from './CreateSyndicate';
import DealInviteLinkPage from './DealInviteLink';
import DiscoverSyndicates from './DiscoverSyndicates';
import fundDashboardRoutes from './FundDashboard/routes';
import investRoutes from './Invest/routes';
import OldPlatformDealInviteLinkPage from './OldPlatformDealInviteLink';
import OldPlatformSyndicateInviteLinkPage from './OldPlatformSyndicateInviteLink';
import onboardingRoutes from './Onboarding/routes';
import RouteNotFound from './RouteNotFound';
import syndicateDashboardRoutes from './SyndicateDashboard/routes';
import SyndicateInvitePage from './SyndicateInvite';
import SyndicateInviteLinkPage from './SyndicateInviteLink';
import UnderMaintenance from './UnderMaintenance';

const MAINTENANCE_QUERY = gql(`
  query MaintenanceQuery {
    maintenanceMode
  }
`);

const MaintenanceRoute = () => {
  const navigate = useNavigate();
  const { data, loading, error } = useQuery(MAINTENANCE_QUERY);

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} />;

  if (data.maintenanceMode && window.location.pathname !== '/maintenance') {
    navigate('/maintenance');
    return null;
  }

  return (
    <div className="relative">
      {isAdminLoggingInAsUser() && (
        <div className="fixed bottom-0 bg-red-500 h-10 w-screen z-50 flex justify-center items-center text-white">
          You are logged in as <span className="ml-1 font-semibold">{getUser()?.email}</span>.
          Please{' '}
          <button className="mx-1 underline font-semibold" onClick={() => logout()}>
            log out
          </button>{' '}
          after you are done.
        </div>
      )}
      <Outlet />
    </div>
  );
};

const router = createBrowserRouter([
  {
    element: <MaintenanceRoute />,
    errorElement: (
      <ErrorMessage
        title="The application encountered an error"
        message="We have been notified of the issue and are working to resolve it. If the issue persists after retrying, please contact support at hi@angellistindia.com"
        refetch={() => window.location.reload()}
      />
    ),
    children: [
      {
        path: '*',
        element: <RouteNotFound />,
      },
      {
        path: '/',
        element: (
          <ProtectedRoute>
            <Navigate to="/invest" />
          </ProtectedRoute>
        ),
      },
      ...authenticationRoutes,
      ...onboardingRoutes,
      ...investRoutes,
      ...syndicateDashboardRoutes,
      ...fundDashboardRoutes,
      {
        path: '/create-syndicate/:type',
        element: (
          <ProtectedRoute>
            <CreateSyndicate />
          </ProtectedRoute>
        ),
      },
      {
        path: '/create-syndicate/',
        element: (
          <ProtectedRoute>
            <CreateSyndicate />
          </ProtectedRoute>
        ),
      },
      {
        path: '/create-fund',
        element: (
          <ProtectedRoute>
            <CreateFund />
          </ProtectedRoute>
        ),
      },
      {
        path: '/syndicate-invites/:id',
        element: (
          <ProtectedRoute>
            <SyndicateInvitePage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/deal-invite-links/:dealInviteLinkId',
        element: (
          <ProtectedRoute>
            <DealInviteLinkPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/syndicate-invite-links/:syndicateInviteLinkId',
        element: (
          <ProtectedRoute>
            <SyndicateInviteLinkPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/old-platform/deal-access-links/:dealInviteLinkId',
        element: (
          <ProtectedRoute>
            <OldPlatformDealInviteLinkPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/old-platform/syndicate-access-links/:syndicateInviteLinkId',
        element: (
          <ProtectedRoute>
            <OldPlatformSyndicateInviteLinkPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'maintenance',
        element: <UnderMaintenance />,
      },
      {
        path: 'discover-syndicates',
        children: [
          {
            index: true,
            element: <DiscoverSyndicates />,
          },
          {
            path: ':syndicateId',
            element: <DiscoverSyndicates />,
          },
        ],
      },
    ],
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
