import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { useState } from 'react';

import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';
import LoadingIndicator from 'primitives/LoadingIndicator';

const GET_FUND_POST_QUERY = gql(`
  query GetFundPostForPublish($id: ID!) {
    fundPost(id: $id) {
      id
      status
      investment {
        deal {
          companyInformation {
            company {
              name
            }
          }
        }
      }
    }
  }
`);

const SUBMIT_FOR_APPROVAL = gql(`
  mutation PublishFundPostFromDeal($id: ID!) {
    submitFundPostForApproval(id: $id) {
      id
      status
    }
  }
`);

const PublishFundPostDialogButton = ({ id }: { id: string }) => {
  const [open, setOpen] = useState(false);
  const { data, error, loading } = useQuery(GET_FUND_POST_QUERY, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  const [publishFundPost, { loading: publishLoading, error: publishError }] =
    useMutation(SUBMIT_FOR_APPROVAL);

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} />;

  const fundPost = data.fundPost;

  function getTargetAudience() {
    if (!fundPost.investment) {
      return 'all your investors';
    }

    return `all your investors with updates regarding ${fundPost.investment.deal.companyInformation.company.name}`;
  }

  return (
    <>
      <Button loading={publishLoading} onClick={() => setOpen(true)}>
        Submit for Approval
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel>
          <DialogTitle>Submit this post</DialogTitle>
          <DialogSubTitle>
            This action will send this post to{' '}
            <span className="font-bold">{getTargetAudience()}</span>, once approved by our admins.
          </DialogSubTitle>
          {publishError && <ErrorMessage error={publishError} />}
          <DialogActions className="sm:grid-cols-2">
            <Button variant="secondary" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() => publishFundPost({ variables: { id } }).then(() => setOpen(false))}
            >
              Submit for Approval
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default PublishFundPostDialogButton;
