import ProtectedRoute from 'components/ProtectedRoute';

import Onboarding from '.';
import BasicInformationInvestmentEntity from '../Invest/InvestmentEntities/CreateInvestmentEntity/BasicInformation';
import InvestorApplication from '../Invest/InvestmentEntities/CreateInvestmentEntity/InvestorApplication';
import Kyc from '../Invest/InvestmentEntities/CreateInvestmentEntity/Kyc';
import ProfileCompletionFlow from './ProfileCompletionFlow';
import SubmitUserForReview from './ProfileCompletionFlow/SubmitUserForReview';
import UpdateUserProfile from './ProfileCompletionFlow/UpdateUserProfile';
import RUVOnboardingFlow from './RUVOnboardingFlow';
import RUVCompanyProfile from './RUVOnboardingFlow/RUVCompanyProfile';
import UpdateUserProfileRUV from './RUVOnboardingFlow/UpdateUserProfileRUV';

const routes = [
  {
    path: '/onboarding',
    children: [
      {
        element: (
          <ProtectedRoute>
            <Onboarding />
          </ProtectedRoute>
        ),
        index: true,
      },
      {
        path: 'complete-profile',
        element: (
          <ProtectedRoute>
            <ProfileCompletionFlow />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <UpdateUserProfile />
              </ProtectedRoute>
            ),
          },
          {
            path: 'social-profile',
            element: (
              <ProtectedRoute>
                <UpdateUserProfile />
              </ProtectedRoute>
            ),
          },
          {
            path: 'social-profile/:investmentEntityId',
            element: (
              <ProtectedRoute>
                <UpdateUserProfile />
              </ProtectedRoute>
            ),
          },
          {
            path: 'investment-entity',
            element: (
              <ProtectedRoute>
                <BasicInformationInvestmentEntity />
              </ProtectedRoute>
            ),
          },
          {
            path: 'investment-entity/:investmentEntityId/basic-information',
            element: (
              <ProtectedRoute>
                <BasicInformationInvestmentEntity />
              </ProtectedRoute>
            ),
          },
          {
            path: 'investment-entity/:investmentEntityId/kyc',
            element: (
              <ProtectedRoute>
                <Kyc />
              </ProtectedRoute>
            ),
          },
          {
            path: 'investment-entity/:investmentEntityId/investor-application',
            element: (
              <ProtectedRoute>
                <InvestorApplication />
              </ProtectedRoute>
            ),
          },
          {
            path: 'investment-entity/:investmentEntityId/submit-for-review',
            element: (
              <ProtectedRoute>
                <SubmitUserForReview />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'complete-profile-ruv',
        element: (
          <ProtectedRoute>
            <RUVOnboardingFlow />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <UpdateUserProfileRUV />
              </ProtectedRoute>
            ),
          },
          {
            path: 'social-profile',
            element: (
              <ProtectedRoute>
                <UpdateUserProfileRUV />
              </ProtectedRoute>
            ),
          },
          {
            path: 'social-profile/:investmentEntityId',
            element: (
              <ProtectedRoute>
                <UpdateUserProfileRUV />
              </ProtectedRoute>
            ),
          },
          {
            path: 'investment-entity/:investmentEntityId/kyc',
            element: (
              <ProtectedRoute>
                <Kyc />
              </ProtectedRoute>
            ),
          },
          {
            path: 'create-syndicate/ruv/:investmentEntityId',
            element: (
              <ProtectedRoute>
                <RUVCompanyProfile />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
];

export default routes;
