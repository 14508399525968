import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DashboardSyndicatesEnumType, InvestmentEntityStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';

import { useSyndicateOnDisplay } from './index';

const BACKED_SYNDICATES_QUERY = gql(`
  query BackedSyndicates($filters: DashboardSyndicatesFilterType, $investmentEntityFilters: DashboardInvestmentEntitiesFilterType, $cursor: ID, $limit: Int) {
    dashboard {
      id
      syndicates(filters: $filters, cursor: $cursor, limit: $limit) {
        nodes {
          id
          name
          image
          statistics {
            id
            topStage
            topIndustry
          }
          userConnections {
            statistics {
              totalInvestedAmount
              numberOfInvestments
              numberOfDealInvites
            }
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
      investmentEntities(filters: $investmentEntityFilters) {
        id
        status
      }
    }
  }
`);

const BackedSyndicates: FC = () => {
  const { setSyndicateOnDisplay } = useSyndicateOnDisplay();

  const { loading, error, data, refetch, fetchMore } = useQuery(BACKED_SYNDICATES_QUERY, {
    variables: {
      limit: 50,
      filters: {
        type: DashboardSyndicatesEnumType.Backed,
      },
      investmentEntityFilters: {
        status: InvestmentEntityStatusEnumType.Active,
      },
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const dashboard = data.dashboard;

  if (!dashboard.syndicates) return <ErrorMessage title="No syndicates found" refetch={refetch} />;

  const syndicates = dashboard.syndicates.nodes;
  const investmentEntities = dashboard.investmentEntities;
  const pageInfo = dashboard.syndicates.pageInfo;

  return (
    <>
      <DataTable
        data={syndicates}
        onClick={syndicate => setSyndicateOnDisplay(syndicate.id)}
        columns={[
          {
            label: '',
            fieldName: 'image',
            type: 'IMAGE',
          },
          {
            label: 'Name',
            fieldName: 'name',
          },
          {
            label: 'Top Stage',
            fieldName: 'statistics.topStage',
            type: 'STAGE',
          },
          {
            label: 'Top Industry',
            fieldName: 'statistics.topIndustry',
            type: 'INDUSTRY',
          },
          {
            label: 'Amount Invested',
            fieldName: 'userConnections.statistics.totalInvestedAmount',
            type: 'CURRENCY',
          },
          {
            label: '# Investments',
            fieldName: 'userConnections.statistics.numberOfInvestments',
            type: 'NUMBER',
          },
          {
            label: 'Deal Invites Sent',
            fieldName: 'userConnections.statistics.numberOfDealInvites',
            type: 'NUMBER',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
      <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-xl font-semibold leading-6 text-gray-700">
            Start your own Syndicate or Fund
          </h3>
          <div className="mt-2 sm:flex sm:items-start sm:justify-between">
            <div className="max-w-xl text-sm text-gray-500">
              <p>
                AngelList India gives you everything you need to build your network, raise capital,
                and grow your portfolio.
              </p>
            </div>
            <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center gap-x-2">
              <NavLink to="/create-syndicate">
                <Button
                  disabled={investmentEntities.length === 0}
                  disabledMessage="You need at least one active investment entity to create a syndicate"
                >
                  Create Syndicate
                </Button>
              </NavLink>
              <NavLink to="/create-fund">
                <Button disabled={true} disabledMessage="Coming soon...">
                  Create Fund
                </Button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-xl font-semibold leading-6 text-gray-700">
            Create a Roll Up Vehicle
          </h3>
          <div className="mt-2 sm:flex sm:items-start sm:justify-between">
            <div className="max-w-xl text-sm text-gray-500">
              <p>Launch your round, close investors, and collect capital - all in one place</p>
            </div>
            <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center gap-x-2">
              <NavLink to="/create-syndicate/ruv">
                <Button
                  disabled={investmentEntities.length === 0}
                  disabledMessage="You need at least one active investment entity to create a RUV"
                >
                  Get Started
                </Button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BackedSyndicates;
