import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { AcceptanceStatusEnumType, IndustryTypeEnumType } from '__generated__/graphql';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FocusLayout from 'layouts/FocusLayout';

import ErrorMessage from 'components/ErrorMessage';

import Badge from 'primitives/Badge';
import LoadingIndicator from 'primitives/LoadingIndicator';

import alIndiaLogo from 'media/alIndiaLogo-fixed.png';

import constants from 'utils/constants';
import acceptanceStatusToReadable from 'utils/enums/acceptance-status-to-readable';
import industryToReadable from 'utils/enums/industry-to-readable';

import DiscoverSyndicate from './DiscoverSyndicate';

const DISCOVER_SYNDICATES_QUERY = gql(`
  query DiscoverSyndicates {
    discoverSyndicates {
      id
      name
      image
      tagline
      statistics {
        id
        topIndustry
        acceptanceStatus
      }
    }
  }
`);

const DiscoverSyndicates: FC = () => {
  const { loading, error, data, refetch } = useQuery(DISCOVER_SYNDICATES_QUERY);
  const navigate = useNavigate();
  const { syndicateId: urlSyndicateId } = useParams();

  const [open, setOpen] = useState(false);
  const [syndicateId, setSyndicateId] = useState('');

  useEffect(() => {
    if (urlSyndicateId) {
      setSyndicateId(urlSyndicateId);
      setOpen(true);
    }
  }, [urlSyndicateId]);

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  if (!data.discoverSyndicates)
    return <ErrorMessage title="No syndicates found" refetch={refetch} />;

  const syndicates = data.discoverSyndicates;

  return (
    <FocusLayout className="sm:max-w-7xl">
      <CoverImage />
      <div className="grid grid-cols-3 gap-x-8 gap-y-4 mt-8 mb-32">
        {syndicates.map(syndicate => (
          <div
            key={syndicate.id}
            className="p-8 bg-white border rounded-md transition-transform duration-200 hover:scale-105 cursor-pointer flex flex-col h-full"
            onClick={() => {
              navigate(`/discover-syndicates/${syndicate.id}`);
            }}
          >
            <div className="flex flex-row space-x-4">
              <img
                className="w-24 h-24 rounded-md p-1"
                src={syndicate.image ?? constants.ALT_AVATAR_URL}
                alt={syndicate.name}
                style={{ objectFit: 'contain', objectPosition: 'top' }}
              />
              <div className="space-y-1">
                <p className="font-bold text-xl">{syndicate.name}</p>
                <p className="text-sm text-gray-500">{syndicate.tagline}</p>
              </div>
            </div>
            <div className="flex flex-row justify-between mt-auto pt-8 flex-wrap space-y-1">
              <div className="flex flex-row space-x-1">
                <p className="text-xs text-gray-500 self-center">Top Industry</p>
                <IndustryBadge industry={syndicate.statistics.topIndustry} />
              </div>
              <div className="flex flex-row space-x-1">
                <p className="text-xs text-gray-500 self-center">Accepting LPs</p>
                <AcceptanceStatusBadge acceptanceStatus={syndicate.statistics.acceptanceStatus} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <DiscoverSyndicate open={open} onClose={() => setOpen(false)} syndicateId={syndicateId} />
    </FocusLayout>
  );
};

const IndustryBadge: FC<{ industry: IndustryTypeEnumType | null | undefined }> = ({ industry }) => {
  if (!industry) return <Badge label={'-'} color={'#4f46e5'} />;
  const { label: industryLabel, color: industryColor } = industryToReadable(industry);
  return <Badge label={industryLabel} color={industryColor} />;
};

const AcceptanceStatusBadge: FC<{
  acceptanceStatus: AcceptanceStatusEnumType | null | undefined;
}> = ({ acceptanceStatus }) => {
  if (!acceptanceStatus) return <Badge label={'-'} color={'#4f46e5'} />;
  const { label: acceptanceStatusLabel, color: acceptanceStatusColor } =
    acceptanceStatusToReadable(acceptanceStatus);
  return <Badge label={acceptanceStatusLabel} color={acceptanceStatusColor} />;
};

const CoverImage: FC = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-[#3c4794] rounded-xl mb-5 relative bg-no-repeat bg-[length:500px_500px] bg-[120%_5rem] my-4">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <img
          src={alIndiaLogo}
          alt="Discover Syndicates"
          className="mx-auto w-[40rem] h-auto object-contain  p-4 rounded-md mb-8"
        />
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-5xl">
            Discover Syndicates
          </h2>
        </div>
        <p
          className="mx-auto mt-6 max-w-xl text-center text-2xl leading-8 text-indigo-200 underline cursor-pointer"
          onClick={() => navigate('/invest/help#general')}
        >
          Learn more about Syndicates &#8594;
        </p>
      </div>
    </div>
  );
};

export default DiscoverSyndicates;
