import {
  FundStatusEnumType,
  SyndicateStatusEnumType,
  SyndicateTypeEnumType,
} from '__generated__/graphql';

import bankIcon from 'media/icons/bank.svg';
import wishlistIcon from 'media/icons/binoculars.svg';
import dealIcon from 'media/icons/deal.svg';
import helpIcon from 'media/icons/help.svg';
import messageIcon from 'media/icons/message.svg';
import networkIcon from 'media/icons/network.svg';
import notepadIcon from 'media/icons/notepad.svg';
import portfolioIcon from 'media/icons/portfolio.svg';
import settingsIcon from 'media/icons/settings.svg';
import syndicateIcon from 'media/icons/syndicate.svg';
import teamIcon from 'media/icons/team.svg';

interface TabType {
  key: string;
  label: string;
  icon: string;
  route: string;
  exact?: boolean;
  disabled?: boolean;
  trailingIcon?: React.ReactNode;
}

const getInvestorTabs: () => TabType[] = () => [
  {
    key: 'portfolio',
    label: 'Portfolio',
    icon: portfolioIcon,
    route: '/invest',
    exact: true,
  },
  {
    key: 'syndicates',
    label: 'Syndicates',
    icon: syndicateIcon,
    route: '/invest/syndicates',
  },
  {
    key: 'deal-invites',
    label: 'Deal Invites',
    icon: dealIcon,
    route: '/invest/deal-invites',
  },
  {
    key: 'wishlist',
    label: 'Wishlist',
    icon: wishlistIcon,
    route: '/invest/wishlist',
    trailingIcon: <div className="px-1 bg-orange-400 rounded-sm -skew-x-6 text-white">NEW</div>,
  },
  {
    key: 'investment-entities',
    label: 'Investment Entities',
    icon: bankIcon,
    route: '/invest/investment-entities',
  },
  {
    key: 'conversations',
    label: 'Messages',
    icon: messageIcon,
    route: '/invest/conversations',
  },
  {
    key: 'posts',
    label: 'Posts',
    icon: notepadIcon,
    route: `/invest/posts`,
  },
  {
    key: 'fund-posts',
    label: 'Fund Posts',
    icon: notepadIcon,
    route: `/invest/fund-posts`,
  },
  {
    key: 'settings',
    label: 'Settings',
    icon: settingsIcon,
    route: '/invest/settings',
  },
  {
    key: 'help',
    label: 'Help',
    icon: helpIcon,
    route: `/invest/help`,
  },
];

const getSyndicateDashboardTabs: (
  syndicateId: string,
  status?: SyndicateStatusEnumType,
  syndicateType?: SyndicateTypeEnumType.Default | SyndicateTypeEnumType.Ruv
) => TabType[] = (syndicateId, status, syndicateType) => {
  return [
    {
      key: 'portfolio',
      label: 'Portfolio',
      icon: portfolioIcon,
      route: `/syndicate-dashboard/${syndicateId}`,
      exact: true,
    },
    {
      key: 'deals',
      label: syndicateType === SyndicateTypeEnumType.Ruv ? 'RUVs' : 'Deals',
      icon: dealIcon,
      route: `/syndicate-dashboard/${syndicateId}/deals`,
      disabled: status !== SyndicateStatusEnumType.Active,
    },
    {
      key: 'network',
      label: 'Network',
      icon: networkIcon,
      route: `/syndicate-dashboard/${syndicateId}/network`,
      disabled: status !== SyndicateStatusEnumType.Active,
    },
    {
      key: 'team',
      label: 'Team',
      icon: teamIcon,
      route: `/syndicate-dashboard/${syndicateId}/team`,
      disabled: status !== SyndicateStatusEnumType.Active,
    },
    {
      key: 'conversations',
      label: 'Messages',
      icon: messageIcon,
      route: `/syndicate-dashboard/${syndicateId}/conversations`,
      disabled: status !== SyndicateStatusEnumType.Active,
    },
    {
      key: 'posts',
      label: 'Posts',
      icon: notepadIcon,
      route: `/syndicate-dashboard/${syndicateId}/posts`,
      disabled: status !== SyndicateStatusEnumType.Active,
    },
    {
      key: 'settings',
      label: 'Settings',
      icon: settingsIcon,
      route: `/syndicate-dashboard/${syndicateId}/settings`,
    },
    {
      key: 'help',
      label: 'Help',
      icon: helpIcon,
      route: `/syndicate-dashboard/${syndicateId}/help`,
    },
  ];
};

const getRuvDashboardTabs: (syndicateId: string, status?: SyndicateStatusEnumType) => TabType[] = (
  syndicateId,
  status
) => [
  {
    key: 'deals',
    label: 'RUVs',
    icon: dealIcon,
    route: `/syndicate-dashboard/${syndicateId}/deals`,
  },
  {
    key: 'network',
    label: 'Network',
    icon: networkIcon,
    route: `/syndicate-dashboard/${syndicateId}/network`,
    disabled: status !== SyndicateStatusEnumType.Active,
  },
  // {
  //   key: 'conversations',
  //   label: 'Messages',
  //   icon: messageIcon,
  //   route: `/syndicate-dashboard/${syndicateId}/conversations`,
  // },
  // {
  //   key: 'posts',
  //   label: 'Posts',
  //   icon: notepadIcon,
  //   route: `/syndicate-dashboard/${syndicateId}/posts`,
  // },
  {
    key: 'settings',
    label: 'Settings',
    icon: settingsIcon,
    route: `/syndicate-dashboard/${syndicateId}/settings`,
  },
  {
    key: 'help',
    label: 'Help',
    icon: helpIcon,
    route: `/syndicate-dashboard/${syndicateId}/help`,
  },
];

const getFundDashboardTabs: (fundId: string, status?: FundStatusEnumType) => TabType[] = (
  fundId,
  status
) => [
  {
    key: 'portfolio',
    label: 'Portfolio',
    icon: portfolioIcon,
    route: `/fund-dashboard/${fundId}`,
    exact: true,
  },
  // {
  //   key: 'network',
  //   label: 'Network',
  //   icon: networkIcon,
  //   route: `/fund-dashboard/${fundId}/network`,
  // },
  {
    key: 'partners',
    label: 'Limited Partners',
    icon: networkIcon,
    route: `/fund-dashboard/${fundId}/partners`,
    disabled: status !== FundStatusEnumType.Active,
  },
  {
    key: 'investments',
    label: 'Holdings',
    icon: dealIcon,
    route: `/fund-dashboard/${fundId}/investments`,
    disabled: status !== FundStatusEnumType.Active,
  },
  {
    key: 'posts',
    label: 'Posts',
    icon: notepadIcon,
    route: `/fund-dashboard/${fundId}/posts`,
    disabled: status !== FundStatusEnumType.Active,
  },
  {
    key: 'settings',
    label: 'Settings',
    icon: settingsIcon,
    route: `/fund-dashboard/${fundId}/settings`,
  },
];

// const getSidebarTabsForProfile = (
//   profileType: 'invest' | 'syndicate-dashboard' | 'fund-dashboard',
//   syndicateId?: string | null,
//   fundId?: string | null
// ) => {
//   if (profileType === 'syndicate-dashboard') return syndicateDashboardTabs(syndicateId || '');
//   else if (profileType === 'fund-dashboard') return fundDashboardTabs(fundId || '');
//   return investorTabs;
// };

export { getInvestorTabs, getSyndicateDashboardTabs, getFundDashboardTabs, getRuvDashboardTabs };
