import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ClosingStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterBar from 'components/FilterBar';

import LoadingIndicator from 'primitives/LoadingIndicator';

const INVESTMENTS_QUERY = gql(`
  query fetchInvestmentsByInvestmentId($id: ID!, $filters: ClosingsFilterType) {
    investmentEntity(id: $id) {
      id
      name
      statistics {
        closingsStatisticsBreakdownByStatus(
          statuses: [ACTIVE, EXITED, PARTIALLY_EXITED]
          investmentEntityId: $id
        ) {
          status
          numberOfClosings
        }
      }
      closings(filters: $filters) {
        nodes {
          id
          deal {
            id
            companyInformation {
              id
              company {
                id
                name
                image
              }
            }
          }
          currentAmount
          exitedAmount
          status
          wiredAt
          statistics {
            realisedValue
            unrealisedValue
            multipleOnInvestment
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
      fundClosings(filters: { statuses: [ACTIVE, EXITED] }) {
        nodes {
          id
          fund {
            id
            image
            name
            statistics {
              multipleOnInvestment
            }
          }
          amount
          status
          wiredAt
        }
      }
    }
  }
`);

const InvestmentEntityInvestments: FC = () => {
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };

  const { loading, error, data, refetch, fetchMore, variables } = useQuery(INVESTMENTS_QUERY, {
    variables: {
      id: investmentEntityId,
      filters: {
        statuses: [ClosingStatusEnumType.Active, ClosingStatusEnumType.PartiallyExited],
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const closings = data.investmentEntity.closings.nodes;
  const pageInfo = data.investmentEntity.closings.pageInfo;

  const activeClosings =
    data.investmentEntity.statistics.closingsStatisticsBreakdownByStatus.find(
      status => status.status === ClosingStatusEnumType.Active
    )?.numberOfClosings ?? 0;

  const partiallyExitedClosings =
    data.investmentEntity.statistics.closingsStatisticsBreakdownByStatus.find(
      status => status.status === ClosingStatusEnumType.PartiallyExited
    )?.numberOfClosings ?? 0;

  const exitedClosings =
    data.investmentEntity.statistics.closingsStatisticsBreakdownByStatus.find(
      status => status.status === ClosingStatusEnumType.Exited
    )?.numberOfClosings ?? 0;

  const filters = [
    ...(activeClosings + partiallyExitedClosings > 0
      ? [
          {
            label: `Active (${activeClosings + partiallyExitedClosings})`,
            value: [ClosingStatusEnumType.Active, ClosingStatusEnumType.PartiallyExited],
          },
        ]
      : []),
    ...(exitedClosings + partiallyExitedClosings > 0
      ? [
          {
            label: `Exited (${exitedClosings + partiallyExitedClosings})`,
            value: [ClosingStatusEnumType.Exited, ClosingStatusEnumType.PartiallyExited],
          },
        ]
      : []),
  ];

  let fundClosings = data.investmentEntity.fundClosings.nodes;
  fundClosings = fundClosings.map(fundClosing => ({
    ...fundClosing,
    unrealisedValue: fundClosing.fund.statistics.multipleOnInvestment
      ? fundClosing.fund.statistics.multipleOnInvestment * fundClosing.amount
      : '-',
  }));

  return (
    <>
      <h3 className="text-lg font-medium leading-6 text-gray-900">Investments</h3>

      <FilterBar
        filters={filters}
        currentValue={variables?.filters?.statuses}
        onSelect={value => {
          refetch({
            filters: {
              statuses: value as ClosingStatusEnumType[],
            },
          });
        }}
      />
      <DataTable
        data={closings}
        columns={[
          {
            label: 'Company',
            fieldName: 'deal.companyInformation.company',
            type: 'IMAGE_WITH_NAME',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Invest Date',
            fieldName: 'wiredAt',
            type: 'DATE',
          },
          ...(variables?.filters?.statuses?.includes(ClosingStatusEnumType.Active)
            ? [
                {
                  label: 'Invested',
                  fieldName: 'currentAmount',
                  type: 'CURRENCY' as const,
                  note: {
                    text: 'Balance amount after the partial exit',
                    conditionToShow: rowData =>
                      rowData.status === ClosingStatusEnumType.PartiallyExited,
                  },
                },
                {
                  label: 'Unrealised Value',
                  fieldName: 'statistics.unrealisedValue',
                  type: 'CURRENCY' as const,
                },
                {
                  label: 'Multiple',
                  fieldName: 'statistics.multipleOnInvestment',
                  type: 'MULTIPLE' as const,
                },
              ]
            : []),
          ...(variables?.filters?.statuses?.includes(ClosingStatusEnumType.Exited)
            ? [
                {
                  label: 'Invested',
                  fieldName: 'exitedAmount',
                  type: 'CURRENCY' as const,
                  note: {
                    text: 'The portion of the investment that was exited',
                    conditionToShow: rowData =>
                      rowData.status === ClosingStatusEnumType.PartiallyExited,
                  },
                },
                {
                  label: 'Distributed Amount',
                  fieldName: 'statistics.realisedValue',
                  type: 'CURRENCY' as const,
                },
              ]
            : []),
        ]}
        hasNextPage={pageInfo.hasNextPage}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
      <h3 className="text-lg font-medium leading-6 text-gray-900">Fund Investments</h3>
      <DataTable
        data={fundClosings}
        columns={[
          { label: 'Fund', fieldName: 'fund', type: 'IMAGE_WITH_NAME' },
          { label: 'Status', fieldName: 'status', type: 'STATUS' },
          { label: 'Invested', fieldName: 'amount', type: 'CURRENCY' },
          { label: 'Invest Date', fieldName: 'wiredAt', type: 'DATE' },
          { label: 'Unrealised Value', fieldName: 'unrealisedValue', type: 'CURRENCY' },
          {
            label: 'Multiple',
            fieldName: 'fund.statistics.multipleOnInvestment',
            type: 'MULTIPLE',
          },
        ]}
      />
    </>
  );
};

export default InvestmentEntityInvestments;
