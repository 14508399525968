import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import MonthlyDealsChart from 'routes/Invest/Syndicates/SyndicateSlideOver/MonthlyDealsChart';
import PercentageDealsByStageChart from 'routes/Invest/Syndicates/SyndicateSlideOver/PercentageDealsByStageChart';
import SyndicateLeads from 'routes/Invest/Syndicates/SyndicateSlideOver/SyndicateLeads';

import ErrorMessage from 'components/ErrorMessage';

import Badge from 'primitives/Badge';
import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import constants from 'utils/constants';
import industryToReadable from 'utils/enums/industry-to-readable';

const DISCOVER_SYNDICATE_QUERY = gql(`
  query GetDiscoverSyndicate($id: ID!) {
    syndicate(id: $id) {
      id
      name
      image
      tagline
      description
      isPrivate
      status
      createdAt
      updatedAt
      syndicateLeads {
        id
        role
        user {
          id
          image{
            url
            name
          }
          name
        }
      }
      statistics {
        id
        fetchMonthlyDealsFinalizedForLastTwelveMonths {
          month
          count
        }
        percentageOfDealsByStage {
          stage
          percentage
        }
        industries
      }
    }
  }
`);

const DiscoverSyndicate = ({
  open = false,
  syndicateId,
  onClose,
}: {
  open: boolean;
  syndicateId: string;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const { loading, error, data, refetch } = useQuery(DISCOVER_SYNDICATE_QUERY, {
    variables: {
      id: syndicateId,
    },
    skip: !open,
  });

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const syndicate = data.syndicate;

    return (
      <>
        <div className="md:flex md:items-center md:justify-between md:space-x-5">
          <div className="flex items-start space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                <img
                  className="h-12 w-12 rounded-full"
                  src={syndicate.image ?? constants.ALT_AVATAR_URL}
                  alt="Syndicate logo"
                />
                <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">{syndicate.name}</h1>
              <p className="text-sm font-medium text-gray-500">
                Investing since{' '}
                <time dateTime={dayjs(syndicate.createdAt).format('MMMM YYYY')}>
                  {dayjs(syndicate.createdAt).format('MMMM YYYY')}
                </time>
              </p>
            </div>
          </div>
        </div>
        <p className="mt-4 text-base text-gray-700">{syndicate.tagline}</p>
        {syndicate.statistics.industries && (
          <div className="mt-4 inline-flex flex-wrap items-center gap-2">
            {syndicate.statistics.industries.map(industry => {
              const { label, color } = industryToReadable(industry!);
              return <Badge key={industry} color={color} label={label} />;
            })}
          </div>
        )}
        <div className="mt-6">
          <h2 className="text-sm font-medium text-gray-900">About</h2>
          <div
            className="prose prose-sm mt-4 text-gray-500"
            dangerouslySetInnerHTML={{ __html: syndicate.description || '' }}
          />
        </div>

        <div className="mt-10">
          <h2 className="text-sm font-medium text-gray-900 mb-4">Team</h2>
          <SyndicateLeads syndicateLeads={syndicate.syndicateLeads} />
        </div>

        <div className="mt-10">
          <h2 className="text-sm font-medium text-gray-900">Portfolio Stats</h2>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
            <div className="py-2">
              <p className="text-md font-medium text-gray-500">
                {syndicate.statistics.fetchMonthlyDealsFinalizedForLastTwelveMonths?.reduce(
                  (acc, stat) => acc + (stat?.count || 0),
                  0
                )}{' '}
                deal
                {syndicate.statistics.fetchMonthlyDealsFinalizedForLastTwelveMonths?.reduce(
                  (acc, stat) => acc + (stat?.count || 0),
                  0
                ) === 1
                  ? ''
                  : 's'}{' '}
                in the last 12 months
              </p>
              <p className="text-xs text-gray-400">Syndicated Deals</p>
              <MonthlyDealsChart
                data={syndicate.statistics.fetchMonthlyDealsFinalizedForLastTwelveMonths}
              />
            </div>
            <div className="py-2">
              <p className="text-md font-medium text-gray-500">Investments by Stage</p>
              <p className="text-xs text-gray-400">Via SPVs and Funds</p>
              {/* @ts-ignore */}
              <PercentageDealsByStageChart data={syndicate.statistics.percentageOfDealsByStage} />
            </div>
          </div>
        </div>

        <div className="mt-10">
          <h2 className="text-sm font-medium text-gray-900">Disclaimers</h2>
          <div className="prose prose-sm mt-4 text-gray-500">
            Follow-On Rate is the percent of a syndicate's AngelList investments older than 18
            months that raised another round of funding and where the company's valuation increased
            since the time of the syndicate's initial investment.
          </div>
          <div className="prose prose-sm mt-4 text-gray-500">
            The materials on this page are provided for informational and educational purposes only.
            AngelList and its affiliates do not provide investment advice to investors, and the
            information on this page should not be relied upon as research, investment advice or a
            recommendation of any kind.
          </div>
          <div className="prose prose-sm mt-4 text-gray-500">
            Past performance is not indicative of future returns. Examples of past investments by
            syndicates are purely for illustrative purposes. There is no guarantee that any
            syndicate will achieve the same exposure to or quality of portfolio companies held by
            any existing AngelList syndicate funds.
          </div>
        </div>

        <Button
          className="w-full py-3"
          variant="primary"
          onClick={() => navigate(`/invest/syndicates/${syndicate.id}`)}
        >
          Find out more &#8594;
        </Button>
      </>
    );
  }

  return (
    <SlideOver open={open} onClose={onClose}>
      {renderContent()}
    </SlideOver>
  );
};

export default DiscoverSyndicate;
