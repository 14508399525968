import { useQuery } from '@apollo/client';
import { gql } from '__generated__';
import { InvestmentEntityStatusEnumType, UserStatusEnumType } from '__generated__/graphql';
import dayjs from 'dayjs';
import { FC } from 'react';
import { Link, Navigate } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

// import indiaIcon from 'media/icons/india-icon.svg';
// import performanceIcon from 'media/icons/performance-icon.svg';
// import reportingIcon from 'media/icons/reporting-icon.svg';
// import supportIcon from 'media/icons/support-icon.svg';

const USER_ONBOARDING_QUERY = gql(`
  query UserOnboarding {
    dashboard {
      id
      user {
        id
        fullName
        status
        investmentEntities {
          id
          status
          createdAt
        }
      }
    }
  }
`);

// const perks = [
//   {
//     name: 'Built for India',
//     imageSrc: indiaIcon,
//     description:
//       'Deal flows, taxes, regulations and compliance are all different in India. We addressed these unique challengers.',
//   },
//   {
//     name: 'Better Performance',
//     imageSrc: performanceIcon,
//     description:
//       "We've built this from the ground up to be faster, efficient and more reliable than ever before.",
//   },
//   {
//     name: 'Faster Reporting',
//     imageSrc: reportingIcon,
//     description:
//       'We know how important it is to get the information you need quickly. Our new reporting tools are faster than ever.',
//   },
//   {
//     name: 'Help and Support',
//     imageSrc: supportIcon,
//     description:
//       "With our new help center, you'll be able to find the answers you need quickly and easily.",
//   },
// ];

const Onboarding: FC = () => {
  const { loading, error, data, refetch } = useQuery(USER_ONBOARDING_QUERY);

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const user = data.dashboard.user;

  if (user.status === UserStatusEnumType.Active) {
    return <Navigate to="/" />;
  }

  const investmentEntities = data.dashboard.user.investmentEntities;

  const latestDraftInvestmentEntity = [...investmentEntities]
    .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
    .find(entity => entity.status === InvestmentEntityStatusEnumType.Draft);

  return (
    <div className="bg-red-50 h-screen flex justify-center items-center p-10">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-30 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="max-w-2xl py-20 px-20">
          <div>
            <h3 className="text-2xl font-bold tracking-tight text-gray-600 sm:text-2xl mb-2">
              <span className="text-indigo-600">{`${user.fullName}`}</span>
            </h3>
            <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mt-2">
              Welcome to
              <span className="text-indigo-600"> AngelList India</span>
            </h3>
            <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mt-8">
              How do you want to get started ?
            </h3>
            <div className="mt-10 flex gap-x-6">
              <Link
                to={
                  latestDraftInvestmentEntity
                    ? `/onboarding/complete-profile/social-profile/${latestDraftInvestmentEntity.id}`
                    : `/onboarding/complete-profile`
                }
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Invest <span aria-hidden="true">→</span>
              </Link>
              <Link
                to={
                  latestDraftInvestmentEntity
                    ? `/onboarding/complete-profile-ruv/social-profile/${latestDraftInvestmentEntity.id}`
                    : `/onboarding/complete-profile-ruv`
                }
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Create An RUV <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          />
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
