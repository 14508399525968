import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FundPostStatusEnumType } from '__generated__/graphql';
import dayjs from 'dayjs';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';
import EditorFormInput from 'components/QuillEditor/EditorFormInput';

import Badge from 'primitives/Badge';
import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import constants from 'utils/constants';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

import PublishFundPostDialogButton from './PublishFundPostDialogButton';
import SelectFundDeal from './SelectFundInvestment';

const GET_FUND_POST_QUERY = gql(`
  query GetFundPostInSyndicateDashboard($id: ID!) {
    fundPost(id: $id) {
      id
      status
      title
      message
      attachments {
        url
        name
      }
      sender {
        name
      }
      fund {
        id
        name
        image
      }
      investment {
        id
      }
      createdAt
      publishedAt
    }
  }
`);

const UPDATE_FUND_POST_MUTATION = gql(`
  mutation UpdateFundPostOnSyndicate($id: ID!, $title: String!, $message: String!, $investmentId: ID) {
    updateFundPost(id: $id, title: $title, message: $message, investmentId: $investmentId) {  
      id
      status
      title
      message
      investment {
        deal {
          id
          companyInformation {
            id
            company {
              name
            }
          }
        }
      }
      createdAt
    }
  }
`);

const REMOVE_FUND_POST = gql(`
  mutation RemoveFundPostFromDeal($id: ID!) {
    removeFundPost(id: $id) {
      id
      status
    }
  }
`);

const FundPostSlideOver = ({
  open = false,
  fundId,
  onClose,
}: {
  open: boolean;
  fundId: string;
  onClose: () => void;
}) => {
  const { data, error, loading } = useQuery(GET_FUND_POST_QUERY, {
    variables: {
      id: fundId,
    },
    skip: !open,
  });

  const [updateFundPost, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_FUND_POST_MUTATION,
    {
      refetchQueries: ['GetFundPostInSyndicateDashboard', 'GetFundPostForPublish'],
    }
  );

  const [removeFundPost, { loading: removeLoading, error: removeError }] = useMutation(
    REMOVE_FUND_POST,
    {
      update(cache) {
        cache.modify({
          id: cache.identify({
            __typename: 'FundType',
            id: data?.fundPost.fund.id,
          }),
          fields: {
            posts(existingFundPostsRef, { readField }) {
              return {
                ...existingFundPostsRef,
                nodes: existingFundPostsRef.nodes.filter(
                  (fundRef: any) => fundId !== readField('id', fundRef)
                ),
              };
            },
          },
        });
      },
    }
  );

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} />;

    const fundPost = data.fundPost;

    const { label, color } = statusEnumToReadable(fundPost.status);

    return (
      <>
        <div className="flex flex-col bg-gray-100 rounded-lg">
          <div className="flex flex-row p-2 justify-between items-center">
            <div className="flex flex-row p-2 space-x-4">
              <img
                src={fundPost.fund.image ?? constants.ALT_AVATAR_URL}
                style={{
                  width: '50px',
                  height: '50px',
                  border: '0.5px solid #aaa',
                  borderRadius: '1000px',
                }}
                alt="Logo"
              />
              <div>
                <p className="font-bold">{fundPost.fund.name}</p>
                <p className="text-sm">{fundPost.sender.name}</p>
              </div>
            </div>
          </div>
        </div>
        <Badge label={label} color={color} />
        <p className="text-sm text-gray-500 !mt-1">
          {fundPost.status === FundPostStatusEnumType.Published && fundPost.publishedAt ? (
            <>
              Published at {dayjs(fundPost.publishedAt).format('MMMM DD, YYYY hh:mm A')}
              <br />
            </>
          ) : (
            <>
              Created at {dayjs(fundPost.createdAt).format('MMMM DD, YYYY hh:mm A')}
              <br />
            </>
          )}
        </p>
        <FormPanelWithReadMode
          loading={updateLoading}
          error={updateError}
          onSubmit={data => {
            updateFundPost({
              variables: {
                id: fundId,
                title: data.title,
                message: data.content,
                investmentId: data.investment?.id,
              },
            });
          }}
          submitButtonLabel="Save"
          disableEdit={fundPost.status !== FundPostStatusEnumType.Draft}
        >
          <FormInput
            fullWidth
            type="text"
            fieldName="title"
            label="Title"
            defaultValue={fundPost.title}
            validators={{
              required: true,
            }}
          />
          <FormInput
            fullWidth
            type="custom"
            fieldName="investment"
            label="Investment"
            defaultValue={fundPost.investment}
            customInput={SelectFundDeal}
          />
          <FormInput
            fullWidth
            type="custom"
            fieldName="content"
            label="Content"
            defaultValue={fundPost.message}
            customInput={EditorFormInput}
            validators={{
              required: true,
            }}
          />
        </FormPanelWithReadMode>
        {fundPost.attachments && fundPost.attachments.length > 0 && (
          <div className="mt-4">
            <h3 className="text-sm font-medium text-gray-900">Attachments</h3>
            <ul className="mt-2 divide-y divide-gray-100 rounded-md border border-gray-200">
              {fundPost.attachments.map(attachment => (
                <li key={attachment.url} className="flex items-center justify-between py-2 px-4">
                  <span className="truncate text-sm text-gray-500">{attachment.name}</span>
                  <a
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm font-medium text-blue-600 hover:text-blue-500"
                  >
                    View
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        {removeError && <ErrorMessage error={removeError} />}
        {fundPost.status === FundPostStatusEnumType.Draft && (
          <div className="flex gap-x-2">
            <PublishFundPostDialogButton id={fundId} />
            <Button
              loading={removeLoading}
              variant="secondary"
              className="!text-red-500"
              onClick={() => {
                removeFundPost({ variables: { id: fundId } }).then(() => onClose());
              }}
            >
              Remove
            </Button>
          </div>
        )}
      </>
    );
  };

  return (
    <SlideOver open={open} onClose={onClose}>
      {renderContent()}
    </SlideOver>
  );
};

export default FundPostSlideOver;
