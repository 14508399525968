import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import ErrorMessage from 'components/ErrorMessage';
import FundPostCard from 'components/FundPostCard';

import Button from 'primitives/Button';
import EmptyState from 'primitives/EmptyState';
import LoadingIndicator from 'primitives/LoadingIndicator';

import CreateFundPostDialogButton from './CreateFundPostDialogButton';
import FundPostSlideOver from './FundPostSlideOver';

const POSTS_QUERY = gql(`
  query FundPostsQuery($fundId: ID!, $cursor: ID, $limit: Int) {
    fund(id: $fundId) {
      id
      posts(cursor: $cursor, limit: $limit) {
        nodes {
          id
          status
          title
          message
          createdAt
          publishedAt
          dealOfInvestment {
            companyInformation {
              company {
                name
              }
            }
          }
          sender {
            id  
            name
          }
          fund {
            id
            name
            image
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const FundPosts: FC = () => {
  const [postOnDisplay, setPostOnDisplay] = useState('');

  const { fundId } = useParams<{ fundId: string }>() as {
    fundId: string;
  };

  const { loading, error, data, refetch, fetchMore } = useQuery(POSTS_QUERY, {
    variables: {
      fundId,
      limit: 15,
    },
  });

  const renderContent = () => {
    if (loading && !data) return <LoadingIndicator />;

    if (error || !data || !data.fund.posts) return <ErrorMessage error={error} refetch={refetch} />;

    const fund = data.fund;
    const posts = fund.posts.nodes;
    const pageInfo = fund.posts.pageInfo;

    if (posts.length === 0)
      return (
        <EmptyState
          title="No posts received"
          description="Updates and information from funds will appear here"
        />
      );

    return (
      <>
        <FundPostSlideOver
          open={!!postOnDisplay}
          fundId={postOnDisplay}
          onClose={() => setPostOnDisplay('')}
        />
        <div className="flex flex-col">
          <div className="space-y-2 overflow-auto">
            {posts.map(post => (
              <FundPostCard
                key={post.id}
                id={post.id}
                title={post.title}
                message={post.message}
                createdAt={post.createdAt}
                publishedAt={post.publishedAt}
                deal={post.dealOfInvestment}
                sender={post.sender}
                fund={post.fund}
                onClick={() => setPostOnDisplay(post.id)}
                status={post.status}
              />
            ))}
          </div>
          {pageInfo.hasNextPage ? (
            <div className="flex justify-center p-4">
              <Button
                loading={loading}
                onClick={() =>
                  fetchMore({
                    variables: {
                      cursor: pageInfo.cursor,
                    },
                  })
                }
              >
                Load more
              </Button>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Fund Posts"
        subTitle="Broadcast updates and information to your network"
      />
      <SidebarLayoutContent>
        <CreateFundPostDialogButton />
        {renderContent()}
      </SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default FundPosts;
