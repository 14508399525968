import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ClosingStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterBar from 'components/FilterBar';
import ReportDataDiscrepancy from 'components/ReportDataDiscrepancy';

import LoadingIndicator from 'primitives/LoadingIndicator';

const CLOSINGS_QUERY = gql(`
  query getCompletedCommitmentsOfFund(
    $id: ID!
    $cursor: ID
    $limit: Int
    $filters: FundInvestmentsFilterType
  ) {
    fund(id: $id) {
      id
      statistics {
        investmentsStatisticsBreakdownByStatus(
          statuses: [ACTIVE, EXITED, PARTIALLY_EXITED]
        ) {
          status
          numberOfClosings
        }
      }
      investments(cursor: $cursor, limit: $limit, filters: $filters) {
        nodes {
          id
          deal {
            id
            companyInformation {
              id
              company {
                id
                name
                image
              }
            }
          }
          investmentEntity {
            id
            name
          }
          currentAmount
          exitedAmount
          status
          wiredAt
          statistics {
            id
            unrealisedValue
            multipleOnInvestment
            realisedValue
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const FundInvestments: FC = () => {
  const { fundId } = useParams<{ fundId: string }>() as { fundId: string };

  const { loading, error, data, refetch, fetchMore, variables } = useQuery(CLOSINGS_QUERY, {
    variables: {
      id: fundId,
      filters: {
        statuses: [ClosingStatusEnumType.Active, ClosingStatusEnumType.PartiallyExited],
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const closings = data.fund.investments.nodes;
    const pageInfo = data.fund.investments.pageInfo;

    const activeClosings =
      data.fund.statistics.investmentsStatisticsBreakdownByStatus.find(
        status => status.status === ClosingStatusEnumType.Active
      )?.numberOfClosings ?? 0;

    const partiallyExitedClosings =
      data.fund.statistics.investmentsStatisticsBreakdownByStatus.find(
        status => status.status === ClosingStatusEnumType.PartiallyExited
      )?.numberOfClosings ?? 0;

    const exitedClosings =
      data.fund.statistics.investmentsStatisticsBreakdownByStatus.find(
        status => status.status === ClosingStatusEnumType.Exited
      )?.numberOfClosings ?? 0;

    const filters = [
      ...(activeClosings + partiallyExitedClosings > 0
        ? [
            {
              label: `Active (${activeClosings + partiallyExitedClosings})`,
              value: [ClosingStatusEnumType.Active, ClosingStatusEnumType.PartiallyExited],
            },
          ]
        : []),
      ...(exitedClosings + partiallyExitedClosings > 0
        ? [
            {
              label: `Exited (${exitedClosings + partiallyExitedClosings})`,
              value: [ClosingStatusEnumType.Exited, ClosingStatusEnumType.PartiallyExited],
            },
          ]
        : []),
    ];

    return (
      <>
        <FilterBar
          filters={filters}
          currentValue={
            (variables?.filters?.statuses as string[]) || [
              ClosingStatusEnumType.Active,
              ClosingStatusEnumType.PartiallyExited,
            ]
          }
          onSelect={value => {
            refetch({
              filters: {
                statuses: value as ClosingStatusEnumType[],
              },
            });
          }}
        />
        <DataTable
          data={closings}
          emptyListTitle="Your haven't made any investments yet"
          columns={[
            {
              label: 'Company',
              fieldName: 'deal.companyInformation.company',
              type: 'IMAGE_WITH_NAME',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Investment Entity',
              fieldName: 'investmentEntity.name',
            },
            {
              label: 'Invest Date',
              fieldName: 'wiredAt',
              type: 'DATE',
            },
            ...(variables?.filters?.statuses?.includes(ClosingStatusEnumType.Active)
              ? [
                  {
                    label: 'Invested',
                    fieldName: 'currentAmount',
                    type: 'CURRENCY' as const,
                    note: {
                      text: 'Balance amount after the partial exit',
                      conditionToShow: rowData =>
                        rowData.status === ClosingStatusEnumType.PartiallyExited,
                    },
                  },
                  {
                    label: 'Unrealised Value',
                    fieldName: 'statistics.unrealisedValue',
                    type: 'CURRENCY' as const,
                  },
                  {
                    label: 'Multiple',
                    fieldName: 'statistics.multipleOnInvestment',
                    type: 'MULTIPLE' as const,
                  },
                ]
              : []),
            ...(variables?.filters?.statuses?.includes(ClosingStatusEnumType.Exited)
              ? [
                  {
                    label: 'Invested',
                    fieldName: 'exitedAmount',
                    type: 'CURRENCY' as const,
                    note: {
                      text: 'The portion of the investment that was exited',
                      conditionToShow: rowData =>
                        rowData.status === ClosingStatusEnumType.PartiallyExited,
                    },
                  },
                  {
                    label: 'Distributed Amount',
                    fieldName: 'statistics.realisedValue',
                    type: 'CURRENCY' as const,
                    headerNote: 'Post-carry, post-TDS amount remitted to your bank account',
                  },
                ]
              : []),
          ]}
          hasNextPage={pageInfo.hasNextPage}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: pageInfo.cursor,
              },
            })
          }
        />
        <ReportDataDiscrepancy />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation title="Holdings" subTitle="View the investments made by your fund" />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default FundInvestments;
