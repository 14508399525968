import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';
import Select from 'primitives/Select';

import { formatCurrency } from 'utils/format-helper';

type Investment = {
  id: string;
  amount: number;
  deal: {
    id: string;
    companyInformation: {
      id: string;
      company: {
        name: string;
      };
    };
  };
};

const FUND_INVESTMENTS_QUERY = gql(`
  query SearchInvestmentsOfFund($id: ID!) {
    fund(id: $id) {
      id
      investments {
        nodes {
          id
          amount
          deal {
            id
            companyInformation {
              id
              company {
                name
              }
            }
          }
        }
        pageInfo {
          cursor
          hasNextPage
        }
      }
    }
  }
`);

const SelectFundInvestment: FC<{
  onChange: (investment: Investment | undefined) => void;
  value?: Investment;
  label?: string;
  readOnly?: boolean;
  fullWidth?: boolean;
}> = ({ value, onChange, label = '', readOnly, fullWidth }) => {
  const [currentValue, setCurrentValue] = useState<Investment | undefined>(value);

  const { fundId } = useParams<{ fundId: string }>() as {
    fundId: string;
  };

  const { loading, error, data, refetch } = useQuery(FUND_INVESTMENTS_QUERY, {
    variables: {
      id: fundId,
    },
  });

  const options = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const investments = data.fund.investments.nodes;

    return (
      <Select
        fullWidth={fullWidth}
        options={
          investments.map(investment => {
            return {
              label: `${investment.deal.companyInformation.company.name} - ${formatCurrency(
                investment.amount
              )}`,
              value: investment.id,
            };
          }) || []
        }
        onChange={id => {
          setCurrentValue(investments.find(investment => investment.id === id));
          onChange(investments.find(investment => investment.id === id));
        }}
        value={currentValue?.id}
        label={label}
        placeholder="No investment selected"
        readOnly={readOnly}
      />
    );
  };

  return options();
};

export default SelectFundInvestment;
