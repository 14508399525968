import ProtectedRoute from 'components/ProtectedRoute';

import FundClosings from './FundClosings';
import FundInvestments from './FundInvestments';
import FundNetwork from './FundNetwork';
import FundPortfolio from './FundPortfolio';
import FundPosts from './FundPosts';
import FundSettings from './FundSettings';
import SuspendedFund from './SuspendedFund';

const routes = [
  {
    path: '/fund-dashboard/:fundId',
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <FundPortfolio />
          </ProtectedRoute>
        ),
      },
      {
        path: 'network',
        element: (
          <ProtectedRoute>
            <FundNetwork />
          </ProtectedRoute>
        ),
      },
      {
        path: 'partners',
        element: (
          <ProtectedRoute>
            <FundClosings />
          </ProtectedRoute>
        ),
      },
      {
        path: 'investments',
        element: (
          <ProtectedRoute>
            <FundInvestments />
          </ProtectedRoute>
        ),
      },
      {
        path: 'settings',
        element: (
          <ProtectedRoute>
            <FundSettings />
          </ProtectedRoute>
        ),
      },
      {
        path: 'posts',
        element: (
          <ProtectedRoute>
            <FundPosts />
          </ProtectedRoute>
        ),
      },
      {
        path: 'suspended',
        element: <SuspendedFund />,
      },
    ],
  },
];

export default routes;
