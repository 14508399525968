import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FormInput, FormPanel } from 'components/FormPanel';
import EditorFormInput from 'components/QuillEditor/EditorFormInput';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

import SelectFundInvestment from './SelectFundInvestment';

const CREATE_FUND_POST_MUTATION = gql(`
  mutation CreateFundPost($title: String!, $message: String!, $fundId: ID!, $investmentId: ID, $attachments: [Upload]) {
    createFundPost(title: $title, message: $message, fundId: $fundId, investmentId: $investmentId, attachments: $attachments) {
      id
      status
      title
      message
      createdAt
      publishedAt
      attachments {
        url
        name
      }
      investment {
        deal {
          companyInformation {
            company {
              name
            }
          }
        }
      }
      sender {
        id
        name
      }
      fund {
        id
        name
        image
      }
    }
  }
`);

const CreateFundPostDialogButton: FC = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const { fundId } = useParams<{ fundId: string }>() as { fundId: string };

  const [createFundPost, { loading, error }] = useMutation(CREATE_FUND_POST_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          __typename: 'FundType',
          id: fundId,
        }),
        fields: {
          posts(existingFundPostsRef) {
            const newFundPostNodeRef = cache.writeFragment({
              data: data.createFundPost,
              fragment: gql(`
                fragment NewFundPost on FundPostType {
                  id
                  status
                  title
                  message
                  createdAt
                  publishedAt
                  investment {
                    deal {
                      companyInformation {
                        company {
                          name
                        }
                      }
                    }
                  }
                  sender {
                    id
                    name
                  }
                  fund {
                    id
                    name
                    image
                  }
                }
              `),
            });

            const updatedFundPostsRef = {
              nodes: [newFundPostNodeRef, ...existingFundPostsRef.nodes],
              pageInfo: { ...existingFundPostsRef.pageInfo, cursor: data.createFundPost?.id },
            };

            return updatedFundPostsRef;
          },
        },
      });
    },
  });

  return (
    <div>
      <div className="flex justify-end">
        <Button onClick={() => setOpen(true)}>Create Post</Button>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel className="sm:!w-screen sm:!max-w-3xl">
          <DialogTitle>Create a Post</DialogTitle>
          <DialogSubTitle>Send a message to all the LPs in your network</DialogSubTitle>
          <FormPanel
            loading={loading}
            error={error}
            onSubmit={data => {
              createFundPost({
                variables: {
                  fundId,
                  title: data.title,
                  message: data.content,
                  investmentId: data.investment?.id,
                  attachments: data.files ? data.files : undefined,
                },
              }).then(() => {
                setOpen(false);
              });
            }}
            onCancel={() => setOpen(false)}
            buttonRef={buttonRef}
          >
            <FormInput
              fullWidth
              type="text"
              fieldName="title"
              label="Title"
              defaultValue={''}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fullWidth
              type="custom"
              fieldName="investment"
              label="Investment"
              defaultValue={''}
              customInput={SelectFundInvestment}
            />
            <FormInput
              fullWidth
              type="custom"
              fieldName="content"
              label="Content"
              defaultValue={''}
              customInput={EditorFormInput}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fullWidth
              type="upload"
              fieldName="files"
              label="Attachments"
              defaultValue={''}
            />
          </FormPanel>
          <DialogActions className="sm:grid-cols-2">
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Save Draft
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </div>
  );
};

export default CreateFundPostDialogButton;
