import { useQuery } from '@apollo/client';
import { gql } from '__generated__';
import { InvestmentEntityStatusEnumType, UserStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import CreateStepperLayout, { StepperLayoutContent } from 'layouts/CreateStepperLayout';

import ErrorMessage from 'components/ErrorMessage';

import ConfirmButton from 'primitives/ConfirmButton';
import LoadingIndicator from 'primitives/LoadingIndicator';

import { logout } from 'utils/auth';

import RUVOnboardingTabs from './RUVOnboardingTabs';

const PROFILE_COMPLETION_QUERY = gql(`
  query ProfileCompletion {
    dashboard {
      id
      user {
        id
        status
      }
      investmentEntities {
        id
        status
      }
    }
  }
`);

const RUVOnboardingFlow: FC = () => {
  const { loading, error, data, refetch } = useQuery(PROFILE_COMPLETION_QUERY);

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const user = data.dashboard.user;
  const investmentEntities = data.dashboard.investmentEntities;

  if (user.status === UserStatusEnumType.Active) {
    return <Navigate to="/" />;
  }

  const isUserWithActiveEntity = investmentEntities.some(
    entity => entity.status === InvestmentEntityStatusEnumType.Active
  );

  return (
    <div className="relative">
      <CreateStepperLayout>
        <RUVOnboardingTabs isUserWithActiveEntity={isUserWithActiveEntity} />
        <StepperLayoutContent>
          <Outlet />
        </StepperLayoutContent>
      </CreateStepperLayout>
      <ConfirmButton
        className="absolute top-4 right-4"
        variant="secondary"
        onConfirm={logout}
        title="Confirm logout"
        description="Are you sure you want to logout?"
      >
        Logout
      </ConfirmButton>
    </div>
  );
};

export default RUVOnboardingFlow;
