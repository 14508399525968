import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DashboardSyndicatesEnumType } from '__generated__/graphql';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import { useSyndicateOnDisplay } from '../index';

const SUGGESTED_SYNDICATES_QUERY = gql(`
  query SuggestedSyndicates($filters: DashboardSyndicatesFilterType, $cursor: ID, $limit: Int) {
    dashboard {
      id
      syndicates(filters: $filters, cursor: $cursor, limit: $limit) {
        nodes {
          id
          name
          image
          statistics {
            id
            topStage
            topIndustry
            dealFlow
            acceptanceStatus
          }
        }
        pageInfo {
          totalCount
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const SuggestedSyndicates: FC = () => {
  const { setSyndicateOnDisplay } = useSyndicateOnDisplay();
  // const [filtersChanged, setFiltersChanged] = useState<boolean>(false);
  const { syndicateId: urlSyndicateId } = useParams();

  const { loading, error, data, refetch, fetchMore, networkStatus } = useQuery(
    SUGGESTED_SYNDICATES_QUERY,
    {
      variables: {
        limit: 50,
        filters: {
          type: DashboardSyndicatesEnumType.Suggested,
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (urlSyndicateId) {
      setSyndicateOnDisplay(urlSyndicateId);
    }
  }, [urlSyndicateId, setSyndicateOnDisplay]);

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const dashboard = data.dashboard;
  // const investmentPreferences = data.dashboard.user.investmentPreferences;

  // const filters = [
  //   {
  //     type: 'stages',
  //     label: 'Stage',
  //     options: stageOptions,
  //     acceptMultiple: true,
  //     readable: syndicateStageEnumToReadable,
  //   },
  //   {
  //     type: 'industries',
  //     label: 'Industry',
  //     options: industryOptions,
  //     acceptMultiple: true,
  //     readable: industryToReadable,
  //   },
  // ];

  // const activeFilters = getActiveFilters(
  //   variables?.filters,
  //   [
  //     { label: 'statuses', value: investmentPreferences?.statuses || [] },
  //     { label: 'dealFlows', value: investmentPreferences?.dealFlows || [] },
  //     { label: 'stages', value: investmentPreferences?.stages || [] },
  //     { label: 'revenues', value: investmentPreferences?.revenues || [] },
  //     { label: 'geographies', value: investmentPreferences?.geographies || [] },
  //     { label: 'industries', value: investmentPreferences?.industries || [] },
  //   ],
  //   filtersChanged
  // );

  if (!dashboard.syndicates) return <ErrorMessage title="No syndicates found" refetch={refetch} />;

  const syndicates = dashboard.syndicates.nodes;
  const pageInfo = dashboard.syndicates.pageInfo;

  return (
    <>
      {/* <div className="flex items-center justify-between">
        <TextField
          placeholder="Search Syndicates"
          onChange={debounce(e => {
            refetch({
              filters: {
                ...variables?.filters,
                text: e.target.value,
              },
            });
          }, 500)}
        />
      </div> */}
      {/* <FilterPanel
        onChange={() => setFiltersChanged(true)}
        activeFilters={activeFilters}
        filters={filters}
        refetch={refetch}
      /> */}
      <DataTable
        data={syndicates}
        onClick={syndicate => setSyndicateOnDisplay(syndicate.id)}
        columns={[
          {
            label: 'Syndicate',
            fieldName: '',
            type: 'IMAGE_WITH_NAME',
          },
          {
            label: 'Top Stage',
            fieldName: 'statistics.topStage',
            type: 'STAGE',
          },
          {
            label: 'Top Industry',
            fieldName: 'statistics.topIndustry',
            type: 'INDUSTRY',
          },
          {
            label: 'Deal Flow',
            fieldName: 'statistics.dealFlow',
            type: 'DEAL_FLOW',
          },
          {
            label: 'Accepting LPs',
            fieldName: 'statistics.acceptanceStatus',
            type: 'ACCEPTANCE_STATUS',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        filterLoading={
          networkStatus === NetworkStatus.setVariables || networkStatus === NetworkStatus.refetch
        }
        paginationLoading={loading}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
      <div className="max-w-3xl">
        <div className="space-y-4">
          <p className="text-xs text-gray-500">
            This filtering tool and the materials on this page are provided for informational and
            educational purposes only. AngelList India and its affiliates do not provide investment
            advice to investors, and the information on this page should not be relied upon as
            research, investment advice or a recommendation of any kind. This tool filters
            syndicates based on your specified parameters. Excluded from this list are investments
            for which AngelList India does not have permission to disclose publicly.
          </p>
          <p className="text-xs text-gray-500">
            Past performance is not indicative of future returns. Examples of past investments by
            syndicates are purely for illustrative purposes. There is no guarantee that any
            syndicate will achieve the same exposure to or quality of portfolio companies held by
            any existing AngelList India syndicate funds.
          </p>
        </div>
      </div>
    </>
  );
};

export default SuggestedSyndicates;
